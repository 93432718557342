import { FC } from 'react'
import ArrowSvg from '@/public/images/common/arrow-narrow-right_v2.svg'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { Button2 } from '@components-v2/atoms/Button2'
import { GeneralSection } from '@/componentsV2/organisms/GeneralSection'
import { GridCardList } from '@/componentsV2/molecules/GridCardList'
import { TopPageSupport, TopPageSupportCard } from '@/componentsV2/molecules/TopPageSupportCard'
import styles from './index.module.scss'

export const TopPageGrowthSupport: FC = observer(() => {
  const { t } = useTranslation(['index'])
  const items: TopPageSupport[] = [
    {
      title: '資金調達相談',
      meta: '資金調達支援',
      description:
        'TOSHIKAは、あなたのスタートアップの成長を加速させる強力なパートナーです。私たちは、アジアのスタートアップエコシステムの構築を目指し、資金調達の成功に再現性をもたらします。',
      href: 'https://toshika-lp.protocol.ooo/#contact',
      imgSrc: '/images/toppage/fundraising.webp',
    },
    {
      title: '資金調達戦略診断',
      meta: '資金調達支援',
      description:
        '簡単な質問に回答することであなたの資金調達戦略を診断し、資金調達を成功させる上で必要なアプローチを明確にします。資金調達戦略の最適化にご活用ください。',
      href: 'https://invdb.protocol.ooo/qa',
      imgSrc: '/images/toppage/qa.webp',
    },
    {
      title: 'TOSHIKA DB',
      meta: '資金調達支援',
      description:
        'スタートアップに特化した、1万社以上のVC、CVC、事業会社のデータベース。投資ステージや業界情報など、必要な条件でフィルタリングし、あなたのビジネスに最適な投資家をスムーズに見つけることができます。',
      href: 'https://invdb.protocol.ooo/',
      imgSrc: '/images/toppage/db.webp',
    },
  ]
  const Footer = () => (
    <Button2
      href='https://prosupporters.protocol.ooo/'
      componentType='a'
      size='xl'
      hierarchy='secondary-color'
      rightIcon={<ArrowSvg viewBox='0 0 24 24' />}
    >
      {t('button.viewAllSupports')}
    </Button2>
  )
  return (
    <GeneralSection heading={t('title.growthSupport')} withDivider footer={<Footer />}>
      <div className={styles.itemList}>
        <GridCardList>
          {items.map((item) => (
            <TopPageSupportCard key={item.title} support={item} />
          ))}
        </GridCardList>
      </div>
    </GeneralSection>
  )
})
